import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import analytics from "./analytics";
import { useAppSelector } from "../../app/hooks";
import { getCurrentUser } from "../../app/slices/userInfo";
import { isDesktop } from "../../utils/helper.utils";
import { getUserPermissions } from "../../app/slices/roles";
import { defaultCashierRoles, defaultSalesRoles, DefualtManagerRoles } from "../../utils/defaultRoles.utils";
import _ from "lodash";
import { getCurrentShop } from "../../app/slices/shops";

const RouteTracker = () => {
  const location = useLocation();
  const [startTime, setStartTime] = useState<number>(Date.now());
  const currentUser = useAppSelector(getCurrentUser);
  const { shopId } = useAppSelector(getCurrentShop);
  const userId = currentUser?.userId;
  const { subscriptions } = useAppSelector((state) => state);
  const userSubscriptions = subscriptions?.subscriptions[0] || [];
  const userRole = useAppSelector(getUserPermissions);
  const [currentUserRole, setCurrentUserRole] = useState<string>();
  const deviceId = localStorage.getItem("DEVICE_UUID");

  useEffect(() => {
    const isManager = _.isEqual(userRole.permissions, DefualtManagerRoles.rolePermissions);
    const isCashier = _.isEqual(userRole.permissions, defaultCashierRoles.rolePermissions);
    const isSales = _.isEqual(userRole.permissions, defaultSalesRoles.rolePermissions);

    if (isManager) {
      setCurrentUserRole("Manager");
    } else if (isSales) {
      setCurrentUserRole("Sales Person");
    } else if (isCashier) {
      setCurrentUserRole("Cashier");
    } else {
      setCurrentUserRole("Custom");
    }
  }, [userRole]);

  useEffect(() => {
    const handleRouteChange = () => {
      const endTime = Date.now();
      const timeSpent = (endTime - startTime) / 1000;

      analytics.setDefaultProperties({
        flowSource: location.pathname,
        timeSpentOnPage: timeSpent,
        timestamp: new Date().toISOString(),
        subscriptionType: userSubscriptions?.packageName,
        platform: isDesktop() ? "desktop" : "web",
        deviceId: deviceId || "",
        shopId: shopId || "",
        userRole: currentUserRole || "",
        ...(userId && { userId }),
      });

      setStartTime(Date.now());
    };

    handleRouteChange();
  }, [location.pathname]);

  return <></>;
};

export default RouteTracker;
